import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/kenneth/Repos/typebrigade/typebrigade.com/src/components/TemplateBlogPost.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><a parentName="p" {...{
        "href": "https://commercialtype.com/"
      }}>{`Commercial Type`}</a>{`, the highly respected type design studio based in London and New York, are touring North America this fall. On September 1st, 2015, Vancouver will be their first stop for `}<a parentName="p" {...{
        "href": "http://www.meetup.com/typebrigade/events/224125426/"
      }}>{`a very special Type Brigade!`}</a></p>
    <p>{`Type Brigade is `}<a parentName="p" {...{
        "href": "http://meetup.com/typebrigade"
      }}>{`Vancouver’s type design and typography meetup`}</a>{`, where we host speakers every other month. The event is completely `}<strong parentName="p">{`free to attend`}</strong>{` as long as you `}<a parentName="p" {...{
        "href": "http://www.meetup.com/typebrigade/events/224125426/"
      }}>{`register soon`}</a>{`.`}</p>
    <p>{`Commercial Type was formed by designers Paul Barnes and Christian Schwartz. They have been collaborating since 2004 on various
projects, including the award winning `}<a parentName="p" {...{
        "href": "http://showcase.commercialtype.com/guardian"
      }}>{`Guardian family`}</a>{`.`}</p>
    <figure {...{
      "className": "figure--aside figure--breakout"
    }}>{`
  `}<span parentName="figure" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1600px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/93f96f189ba46e61cc0cd2ce2f893da2/56e36/blog-commercial-type-at-type-brigade-1.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAAsTAAALEwEAmpwYAAACHUlEQVR42iVSbU8TYRB8fqBBRBBOAS342gKKCNgq2mKstLEJMWrUL/4eri/X3rV3vV6hjf9jNs5ck/uwt7vPzszOOgyeo7OK9io6a+g+QG8b3fuK/dtor+T5dXQ9BA/RuacGloIttO+y5DD+iOgloj3Ep4pHx0jeY3SEoICwZMMjZUZvlInLltbQf6I4LCE+cZrtL8NfQm8Lg2e4WtJU/w7aawq6GyLChnbOThkv5+iRF5E/CDZ5p3nJmUDiYwxfE0eYYZEIJGUkxdFMRvuI3+pLKk6zg0f6yIf6wxeS3fFEm5hE42hVC8owkOYC+rusOoEMX3EMxlXi2LQp8KSsZ5yygMq+YHiomPjEYHO4x005SR88FWeOIDFtLt9ZsKPk+Ex5VQ/1cWi+OQS7FOXEk2Xi0IzupqhSZ3SglRCz/xiDonoYsK23KQuvbolUWHTIGph8xvSrrOLs9BxZk/RsUkf6iRKMW8wukNXVScLcy2LQtOkkXffA7a8LnLNZpiU8CXm2rCXRi6ikN/4KOhtaijbHI5n/Rlqz2Q+b/bRpw6iKmJO63XzjG8su7N9fumXXLZv/AZPRvs1/6btuOQTbAuzvaENUuzhAHgn1E4TKeTykRigqJylZ6C1sc0irNq4aiSUVu7mUGRRPbTLpwLKGjWuWnhvbpi0uxWbf5a76y05m8AF/eGoKKnmhQkuMjymBtvGkdYKnorboHJ3Q5/+SGXLkqG9PNwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "blog commercial type at type brigade 1",
            "title": "blog commercial type at type brigade 1",
            "src": "/static/93f96f189ba46e61cc0cd2ce2f893da2/29007/blog-commercial-type-at-type-brigade-1.png",
            "srcSet": ["/static/93f96f189ba46e61cc0cd2ce2f893da2/e17e5/blog-commercial-type-at-type-brigade-1.png 400w", "/static/93f96f189ba46e61cc0cd2ce2f893da2/5a190/blog-commercial-type-at-type-brigade-1.png 800w", "/static/93f96f189ba46e61cc0cd2ce2f893da2/29007/blog-commercial-type-at-type-brigade-1.png 1600w", "/static/93f96f189ba46e61cc0cd2ce2f893da2/56e36/blog-commercial-type-at-type-brigade-1.png 1750w"],
            "sizes": "(max-width: 1600px) 100vw, 1600px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span>
      <figcaption parentName="figure">
        <p parentName="figcaption"><cite parentName="p">{`Dala Prisma`}</cite><br parentName="p"></br>{`
Designed by Paul Barnes &`}<br parentName="p"></br>{`
Ben Kiel, 2014`}</p>
      </figcaption>
    </figure>
    <p>{`The talk is about their past five years as Commercial Type:`}</p>
    <blockquote>
      <h2 parentName="blockquote">{`It’s not just about the money, it’s not just about the art.`}</h2>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`For the past five years Paul Barnes and Christian Schwartz have been running one of the most exciting and influential type foundries, `}<a parentName="p" {...{
          "href": "https://commercialtype.com/"
        }}>{`Commercial Type`}</a>{`, with offices in New York and London. They have managed to create both popular and critically acclaimed typefaces for a diverse range of organisations, from the Guardian Newspaper, Vanity Fair magazine, to shirt numbers for Puma at the `}<abbr className="caps">{`FIFA`}</abbr>{` 2010 World Cup. Their designs vary from the utilitarian `}<a parentName="p" {...{
          "href": "https://commercialtype.com/typefaces/graphik"
        }}>{`Graphik`}</a>{` and `}<a parentName="p" {...{
          "href": "https://commercialtype.com/typefaces/stag"
        }}>{`Stag`}</a>{`, through to the playful and expressive `}<a parentName="p" {...{
          "href": "https://commercialtype.com/typefaces/dala_floda"
        }}>{`Dala Floda`}</a>{` and `}<a parentName="p" {...{
          "href": "https://commercialtype.com/typefaces/marian"
        }}>{`Marian`}</a>{`. They will talk about the processes behind their designs, balancing the needs for expression, self expression and functionalism.`}</p>
    </blockquote>
    <h2>{`Location`}</h2>
    <p>{`This Type Brigade Special is sponsored by `}<a parentName="p" {...{
        "href": "http://codecore.ca/"
      }}>{`CodeCore`}</a>{`, who is kindly sharing their space for the event:`}</p>
    <address>
      <a href="https://www.google.ca/maps/place/142+W+Hastings+St,+Vancouver,+BC+V6B/@49.2821585,-123.1086794,17z/data=!3m1!4b1!4m2!3m1!1s0x5486717984d4ef63:0x695af2b5a31aca7b?hl=en">
142 W Hastings St.<br />
Vancouver, <abbr className="caps">BC</abbr>
      </a>
    </address>
    <h2>{`Sponsors?`}</h2>
    <p>{`We have some extra sponsorship slots open right now, if you’re interested in helping make Type Brigade even better. `}<a parentName="p" {...{
        "href": "/sponsors"
      }}>{`More details are here.`}</a></p>
    <p>{`Special thanks to our increasingly frequent collaborator `}<a parentName="p" {...{
        "href": "https://twitter.com/sincerelyross"
      }}>{`Ross Milne`}</a>{`, for helping to bring this event to Vancouver.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      